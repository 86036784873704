const module = 'public'

const places = [
  {
    name: 'certificate',
    path: '/validate/certificate',
    meta: {
      module,
      layout: 'minimal'
    }
  },
  {
    name: 'verificate',
    path: '/register/verify',
    meta: {
      module,
      layout: 'minimal'
    }
  },
  {
    name: 'info',
    path: '/diplomado/:idCareer',
    meta: {
      module,
      layout: 'minimal'
    }
  }
]

export default places
