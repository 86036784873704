<template>
  <el-aside width="200px">
    <el-scrollbar>
      <el-menu
        default-active="1"
        :collapse="menuState"
        text-color="#999"
        active-text-color="#fff"
        background-color="#213d5b"
        :router="true"
      >

        <!--
          @open="handleOpen"
        @close="handleClose" -->
        <el-menu-item index="1" :route="{ name: 'dashboard' }">
          <el-icon><Odometer /></el-icon>
          <template #title>Dashboard</template>
        </el-menu-item>

        <el-sub-menu index="2">
          <template #title>
            <el-icon><User /></el-icon>
            <span>Usuarios</span>
          </template>
          <!-- Usuarios Django -->
          <!-- <el-menu-item index="2-1" :route="{ name: 'users' }">Estudiantes</el-menu-item> -->

          <el-menu-item index="2-1" :route="{ name: 'users' }">
            Listar
          </el-menu-item>
          <el-menu-item index="2-2" :route="{ name: 'userEdit', params: { userId: 'new' }  }">
            Nuevo
          </el-menu-item>
        </el-sub-menu>

        <!-- <el-sub-menu index="3">
          <template #title>
            <el-icon><CollectionTag /></el-icon>
            <span>Cursos</span>
          </template>
          <el-menu-item index="3-1">Estudiantes</el-menu-item>
          <el-menu-item index="3-2">Profesores</el-menu-item>
          <el-menu-item index="3-3">Staff</el-menu-item>
        </el-sub-menu> -->

        <el-menu-item index="5" :route="{ name: 'settings' }">
          <el-icon><Setting /></el-icon>
          <template #title>Configuración</template>
        </el-menu-item>

      </el-menu>
    </el-scrollbar>
  </el-aside>
</template>
<script setup>
const menuItems = []
let menuState = false

const menuChange = () => {}
const menuBuild = () => {}
</script>
