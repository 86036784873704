import http from '../../libs/http'

const register = ({ firstname, lastname, username, email, password }) => {
  return http.post('auth/register', {
    firstname,
    lastname,
    username,
    email,
    password
  })
}

const validate = code => {
  return http.post(
    'auth/validate',
    {
      code
    }
  )
}

const email = email => {
  return http.get(
    `auth/email/${email}`
  )
}

const username = username => {
  return http.get(
    `auth/username/${username}`
  )
}

export {
  register,
  validate,
  email,
  username
}
