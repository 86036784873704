const places = [
  // Common routes
	{
		name: 'home',
		path: '/'
	},
	{
		name: 'login',
		path: '/login',
		meta: {
			layout: 'full'
		}
	},
	{
		name: 'register',
		path: '/register',
		meta: {
			layout: 'full'
		}
	},
	{
		name: 'forgot',
		path: '/forgot',
		meta: {
			layout: 'full'
		}
	},
  // Admin routes
  // User routes
  // Special routes
  {
    name: 'logout',
    path: '/logout',
    meta: {
			layout: 'full'
		}
  },
  {
    name: 'not-found',
    path: '/:pathMatch(.*)*',
		meta: {
			layout: 'full'
		}
  }
]

export default places
