<template>
  <el-config-provider :locale="locale">
    <full-layout v-if="$route.meta.layout === 'full'" />
    <admin-layout v-else-if="$route.meta.layout === 'admin'" />
    <minimal-layout v-else-if="$route.meta.layout === 'minimal'" />
    <default-layout v-else />
  </el-config-provider>
</template>
<script setup>
import { ref, onMounted } from 'vue'
import { ElConfigProvider } from 'element-plus'
import DefaultLayout from './layouts/default.layout.vue'
import FullLayout from './layouts/full.layout.vue'
import AdminLayout from './layouts/admin.layout.vue'
import MinimalLayout from './layouts/minimal.layout.vue'

import es from 'element-plus/dist/locale/es.min.mjs'

const locale = ref(es)

onMounted(() => {
  document.body.classList.add('ready');
  setTimeout(
  function() {
    document.body.classList.remove('preload','ready');
    document.body.classList.add('loaded');
  },
  1500);
})
</script>
