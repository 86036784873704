import UserRoles from '../services/auth/user.roles'

const module = 'admin'

const places = [
  {
		name: 'dashboard',
		path: '/dashboard',
    meta: {
      module,
      layout: module,
      roles: [
        UserRoles.ADMIN,
        UserRoles.STAFF
      ]
    }
	},
  {
		name: 'users',
		path: '/users',
    meta: {
      module,
      layout: module,
      roles: [
        UserRoles.ADMIN,
        UserRoles.STAFF
      ]
    }
	},
  {
		name: 'userEdit',
		path: '/users/:userId',
    meta: {
      module,
      layout: module,
      roles: [
        UserRoles.ADMIN,
        UserRoles.STAFF
      ]
    }
	},
  {
		name: 'settings',
		path: '/settings',
    meta: {
      module,
      layout: module,
      roles: [
        UserRoles.ADMIN,
        UserRoles.STAFF
      ]
    }
	},
]

export default places
