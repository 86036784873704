import UserRoles from '../services/auth/user.roles'

const module = 'course'

const places = [
  {
		name: 'course',
		path: '/course/:courseSlug',
    meta: {
      module,
      layout: module
    }
	},
  {
		name: 'lecture',
		path: '/course/:courseSlug/lecture/:lectureSlug?',
    meta: {
      module,
      layout: module
    }
	}
]

export default places
