<template>
  <section class="newsletter-box" style="display: none;">
    <p class="text-bold">
      {{ $t('components.footer.newsletter.title') }}
    </p>
    <form action="">
      <input type="email" :placeholder="$t('components.footer.newsletter.placeholder')" />
      <button type="submit">
        {{ $t('components.footer.newsletter.button') }}
      </button>
    </form>
  </section>
</template>
<style lang="sass">
.newsletter-box
  display: flex
  flex-direction: row
  justify-content: space-around
  align-items: center

  p
    font-size: 1.2rem
    width: 50%

  form
    display: flex
    // gap: 1rem
    width: 50%

    input
      border: 1px solid white
      background-color: white
      flex: 1
      height: 1.5rem
      outline: none
      padding: 0.5rem
      width: 80%

    button
      border: none
      background-color: green
      color: #fff
      cursor: pointer
      font-weight: bold
      flex: 0 0 auto
      text-transform: uppercase
      padding: 0rem 1rem

@media screen and (max-width: 1024px)
  .newsletter-box
    padding: 0 .3rem

    p
      font-size: 1rem
      width: 45%

    form
      font-size: 1rem

@media screen and (max-width: 500px)
  .newsletter-box
    flex-direction: column
    justify-content: center

    p
      font-size: 1rem
      text-align: center
      width: 100%

    form
      font-size: 0.8rem
      width: 95%

</style>
