const API_URL = '/api'

const FOOTER_LINKS = [
  {
    name: 'components.footer.links.faq',
    url: 'https://www.pisciculturaglobal.com/blog/'
  },
  {
    name: 'components.footer.links.contact_us',
    url: 'https://www.pisciculturaglobal.com/#contact'
  },
  {
    name: 'components.footer.links.lives',
    url: 'https://www.youtube.com/@PisciculturaGlobal'
  },
  {
    name: 'components.footer.links.terms',
    url: 'https://www.pisciculturaglobal.com/privacidad/'
  },
  {
    name: 'components.footer.links.privacy',
    url: 'https://www.pisciculturaglobal.com/privacidad/'
  }
]

const ROLES = [
  {
    value: 'admin',
    label: 'Admin'
  },
  {
    value: 'staff',
    label: 'Staff'
  },
  {
    value: 'professor',
    label: 'Profesor'
  },
  {
    value: 'editor',
    label: 'Editor'
  },
  {
    value: 'user',
    label: 'Usuario'
  }
]

const HOMES = {
  admin: 'dashboard',
  staff: 'dashboard',
  professor: 'home',
  editor: 'home',
  user: 'home'
}

export {
  API_URL,
  ROLES,
  HOMES,
  FOOTER_LINKS
}
