import axios from 'axios'
import { useRouter } from 'vue-router'
import { API_URL } from '../config'
import AuthHeader from '../services/auth/auth.header'

const httpInstance = axios.create({
  baseURL: API_URL,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
})

httpInstance.interceptors.request.use(
  config => {
    if (AuthHeader()) {
      config.headers = {
        ...config.headers,
        ...AuthHeader()
      }
    }

    return config
  },
  async error => {
    return error
  }
)

httpInstance.interceptors.response.use(
  response => {
    return response
  },
  async error => {
    if (error.response && error.response.status === 401) {
      const router = useRouter()
      localStorage.removeItem('token')
      router.push('logout')
    }
    return error
  }
)


const http = {
  get: httpInstance.get,
  post: httpInstance.post,
  put: httpInstance.put,
  delete: httpInstance.delete
}

export default http
