import { nextTick } from 'vue'
import { createI18n, useI18n } from 'vue-i18n'

const defaultLanguage = 'es'
const storageLang = 'lang'

const setI18nLanguage = (i18n, locale) => {
  if (i18n.mode === 'legacy') {
    i18n.global.locale = locale
  } else {
    i18n.global.locale.value = locale
  }
  /**
   * NOTE:
   * If you need to specify the language setting for headers, such as the `fetch` API, set it here.
   * The following is an example for axios.
   *
   * axios.defaults.headers.common['Accept-Language'] = locale
   */
  // document.querySelector('html').setAttribute('lang', locale)
  localStorage.setItem(storageLang, locale)
}

const loadLocaleMessages = async (i18n, locale) => {
  // load locale messages with dynamic import
  const messages = await import(`../i18n/${locale}.json`)

  // set locale and locale message
  i18n.global.setLocaleMessage(locale, messages.default)

  return nextTick()
}

const setupI18n = (options = { locale: 'es' }) => {
  const i18n = createI18n(options)

  setI18nLanguage(i18n, options.locale)
  loadLocaleMessages(i18n, options.locale)

  return i18n
}

let systemLanguage = localStorage.getItem(storageLang) || defaultLanguage

const i18n = setupI18n({
  locale: systemLanguage, // set locale
  fallbackLocale: defaultLanguage, // set fallback locale
  // globalInjection: true
})

const t = i18n.global.t

// export default i18n
// export setI18nLanguage
// export loadLocaleMessages
export {
  i18n as default,
  t,
  systemLanguage,
  setI18nLanguage,
  loadLocaleMessages
}
