import http from '@/libs/http'

const login = ({ username, password }) => {
  return http.post('auth/login', {
    username,
    password
  })
}

const logout = () => {
  return http.post('auth/logout')
}

const ping = () => {
  return http.get('auth/ping')
}

export {
  login,
  logout,
  ping
}
