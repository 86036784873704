export default () => {
  const token = localStorage.getItem('token')
  const header = {}

  if (token) {
    // header['x-access-token'] = token
    header['Authorization'] = `Bearer ${token}`
  }

  return header
}
