import { createApp } from 'vue'
import { createRouter, createWebHashHistory} from 'vue-router'
import { createPinia } from 'pinia'

import * as Sentry from '@sentry/vue'

import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import App from './App.vue'
import routes, { checkAuth } from './routes'
import i18n from './i18n'
import filters from './filters'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import {
  faGraduationCap,
  faUser,
  faUsers,
  faAward,
  faUserSecret,
  faUserGraduate
} from '@fortawesome/free-solid-svg-icons'

import {
  faCircleCheck,
  faCircleXmark
} from '@fortawesome/free-regular-svg-icons'

/* add icons to the library */
library.add(
  faGraduationCap,
  faUser,
  faUsers,
  faAward,
  faUserSecret,
  faCircleCheck,
  faCircleXmark,
  faUserGraduate
)

import './sass/style.sass'

const router = createRouter({
	history: createWebHashHistory(),
	routes
})

router.beforeEach(checkAuth)

const app = createApp(App)

// Import Font-awesome Icons
app.component('font-awesome-icon', FontAwesomeIcon)

// Import Icons
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

Sentry.init({
  app,
  dsn: "https://9ccd5bd9cbe6c9350769a2eeeac636c1@o4507171262627840.ingest.us.sentry.io/4507171264659456",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    "https://university.pisciculturaglobal.com/"
    // /^https:\/\/yourserver\.io\/api/
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

app.use(filters)
app.use(router)
app.use(createPinia())
app.use(i18n)

app.mount('#app')
