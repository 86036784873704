const module = 'career'

const places = [
  {
    name: 'career',
    path: '/career/:careerSlug/',
    meta: {
      layout: module
    }
  },
  {
		name: 'exam',
		path: '/careers/:careerSlug/exam',
    meta: {
      module,
      layout: 'minimal'
    }
	},
  {
		name: 'lecture',
		path: '/career/:careerSlug/course/:courseSlug/lecture/:lectureSlug?',
    meta: {
      module: 'course',
      layout: module
    }
	}
]

export default places
