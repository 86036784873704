import DatetimeFormat from "./datetime-format"
import DateFormat from "./dateshort-format"
import NumberFormat from "./number-format"
import Seconds2Time from "./seconds2time"

export default {
  install(Vue) {
    Vue.config.globalProperties.DateFormat = DateFormat
    Vue.config.globalProperties.DatetimeFormat = DatetimeFormat
    Vue.config.globalProperties.NumberFormat = NumberFormat
    Vue.config.globalProperties.Seconds2Time = Seconds2Time
  }
}
